import { BaseDto } from '@/shared/dtos/base-dto';

export class datos_empresa extends BaseDto {
    public nombre !: string;
    public nif !: string;
    public codigo_cuenta_cotizacion !: string;
    public notas !: string;
    public name_imagen!:string;
    public nombre_donde_fichan!:string;
    public activar_otp!:boolean;
 } 
